import { Component } from '@angular/core';
import { PilotService } from './core/services/pilot.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Entity Editor';

  constructor(
    private pilotService : PilotService
  ) { }

  ngOnInit() {

  }

}
