import { Injectable } from '@angular/core';

import * as $ from 'jquery';
import { PilotModel } from '../models/pilot.model';
import { EnvService } from './env.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

/**
 * A service to get pilot select in the User Interface
 */

@Injectable({
  providedIn: 'root'
})
export class PilotService {

  public subjectMenuBarChange = new Subject<boolean>();

  public getSubjectMenuBarChange() {
    return this.subjectMenuBarChange;
  }


  constructor(private envService : EnvService) { }

/**
 * get pilot config from localStorage
 *
 *
 * @returns {PilotModel} obj - Pilot config
 */
  getLocalPilot(): PilotModel {

    let pilot : any = localStorage.getItem(this.envService.storageKeysConfig.localPilot);

    if (pilot == null) {
      //console.log ( 'getLocalPilot null ', pilot );
      let pilotModel = this.convertEnvToModel ( this.findOrionConfig ( environment.orions_configs , 'Guaspari' )   );

      this.setLocalPilot(pilotModel);

      return pilotModel ;

    }
    else {
      pilot  = JSON.parse(pilot);

      let pilotModel = this.findOrionConfig ( environment.orions_configs , pilot.name ) ;

      if ( typeof pilotModel === 'undefined' ){
        pilotModel = this.convertEnvToModel ( this.findOrionConfig ( environment.orions_configs , 'Guaspari' )   );
      }

      this.setLocalPilot(pilotModel);

      return pilotModel;
    }
  }

/**
 * save pilot config in localStorage
 *
 * @param {PilotModel} obj - Pilot config.
 *
 */
  setLocalPilot(obj: PilotModel) {

    //console.log ( 'setLocalPilot ' , obj );

    if (obj == null) {
      let pilotModel = this.convertEnvToModel ( this.findOrionConfig ( environment.orions_configs , 'Guaspari' )   );

      localStorage.setItem(this.envService.storageKeysConfig.localPilot, JSON.stringify(pilotModel));

      //localStorage.removeItem(this.envService.storageKeysConfig.localPilot);
    }
    else {
      localStorage.setItem(this.envService.storageKeysConfig.localPilot, JSON.stringify(obj));
    }
  }

  onPilotChangeService( value : string ){

    //console.log ('onPilotChangeService ' , value);

    let config = this.findOrionConfig ( environment.orions_configs , value );
    //console.log ('onPilotChangeService config' , config);

    let model = this.convertEnvToModel ( config );
    //console.log ('onPilotChangeService model' , model);

    this.setLocalPilot ( model );
    this.contentInit();
  }

  findOrionConfig(configs : any [], name){
    return configs.find(element => element.name == name);
  }

   contentInit()  {
    let pilotModel : PilotModel = this.getLocalPilot();

    //local config does not exists in environment config file
    if (pilotModel == null || this.findConfigOrion(pilotModel) < 0){

      pilotModel = this.convertEnvToModel ( this.findOrionConfig ( environment.orions_configs , 'default' )   );

    }

    this.setLocalPilot ( pilotModel );
    this.setPilotSelected( pilotModel.name );
    //console.log ('pilot selected', pilotModel.name );

  }

  getHeaderOrion(){
    let pilot : PilotModel = this.getLocalPilot();

    // 'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
    // 'Access-Control-Allow-Origin': '*'

    return {
      'fiware-service' : pilot.fiwareService,
      'fiware-servicepath' : pilot.fiwareServicepath
    };
  }

  getAllPilots(){

    let orions = environment.orions_configs;

    let orionModels : PilotModel[]  = orions.map ( element => { return this.convertEnvToModel (element) });

    return orionModels;

  }

  getAllEnablePilots() : PilotModel[] {

    let orions = environment.orions_configs;

    let orionModels : PilotModel[]  = orions.map ( element => { return this.convertEnvToModel (element) });

    return orionModels.filter (value => value.enable == true);

  }

  addOrionConfiguration(config){

    environment.orions_configs.push( config );

    this.getAllPilots();
    this.contentInit();
  }

  updateOrionConfiguration(resource){
    let copy = this.simpleClone(resource);

    let index = environment.orions_configs.findIndex(
      element => {
        return element.name == copy.name

      } );

      environment.orions_configs[index] = copy;

      this.getAllPilots();
      this.contentInit();

      //console.log(index, environment.orions_configs)
  }

  deleteOrionConfiguration(config){

    let index = environment.orions_configs.findIndex(
      element => {
        return element.name == config.name
      } );

      environment.orions_configs.splice(index,1);

      this.getAllPilots();
      this.contentInit();

  }


  //private methods

  private setPilotSelected(value) {
    $('#pilot').val(value);
  }

  private convertEnvToModel(envConfig) : PilotModel{

    let model : PilotModel = {
      urlOrion : envConfig.urlOrion,
      urlQL : envConfig.urlQL,
      fiwareService : envConfig.fiwareService,
      fiwareServicepath: envConfig.fiwareServicepath,
      name : envConfig.name,
      enable : envConfig.enable
    }

    return model;
  }

  private findConfigOrion(localConfig){
    return environment.orions_configs.findIndex(
      element => {
        return element.name == localConfig.name

      } );
  }

  private simpleClone(obj: any) {
    return Object.assign({}, obj);
  }


}
