<!-- Footer -->
<footer id="footer" class="page-footer font-small footer-biota pt-4 mt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0 mt-3">

        <div class="row justify-content-center">

                  <!-- Content -->
          <h5 class="text-uppercase blank">IoT EE</h5>

        </div>

        <div class="row justify-content-center blank">

          <p>IoT Entity Editor</p>
        </div>



      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center blank py-3">© 2020 Copyright:
    <a class="blank" target="blank" href="http://www.swamp-project.org/"> SWAMP Project</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
