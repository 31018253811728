import { Component, OnInit } from '@angular/core';
import { PilotService } from '../../services/pilot.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PilotModel } from '../../models/pilot.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public subjectMenuBar = new Subject();

  orions : PilotModel [] = [];

  constructor(
    private pilotService : PilotService,
    private router : Router,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.getAllEnablePilots();
    this.pilotService.contentInit();

    this.subjectMenuBar = this.pilotService.getSubjectMenuBarChange();

    this.subjectMenuBar.subscribe(resp =>{
      console.log('subjectMenuBar',resp);

      this.getAllEnablePilots();
      this.pilotService.contentInit();
    });
  }

  ngAfterViewChecked(){
      //this.getAllEnablePilots();
      this.pilotService.contentInit();
  }

  onPilotChange(value){

    //console.log('navbar change ' , value);
    this.pilotService.onPilotChangeService(value);
    this.getAllEnablePilots();
    this.reloadCurrentRoute();
    //this.router.navigate(['/']);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;

     //console.log('reloadCurrentRoute', this.pilotService.getLocalPilot() );
     //console.log(currentUrl);

    this.router.navigateByUrl(currentUrl, {skipLocationChange: true} ).then(
      () => {
        this.router.navigate([ currentUrl ]);
        this.toastr.success('Pilot has been updated');
      }
    );
  }

  private getAllEnablePilots(){
    this.orions = this.pilotService.getAllEnablePilots () ;
  }

}
