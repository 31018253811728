import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';


const routes: Routes = [

  {path: '', redirectTo: '/entities',  pathMatch: 'full' },
  {path: 'home', redirectTo: '/entities',  pathMatch: 'full' },
  {path: 'entities' , loadChildren: () => import('./pages/entities/entities.module').then(m => m.EntitiesModule) },
  {path: 'templates' , loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule) },
  {path : 'mock-data', loadChildren: () => import('./pages/mock-data/mock-data.module').then(m => m.MockDataModule) },
  {path : 'subscriptions', loadChildren: () => import('./pages/subscription/subscription.module').then(m => m.SubscriptionModule) },
  {path : 'components-config', loadChildren: () => import('./pages/components-config/components-config.module').then(m => m.ComponentsConfigModule) },
  {path : 'modeler', loadChildren: () => import('./pages/modeler/modeler.module').then(m => m.ModelerModule) },
  {path : 'weather', loadChildren: () => import('./pages/weather/weather.module').then(m => m.WeatherModule) },
  {path : 'api', loadChildren: () => import('./api/api.module').then(m => m.ApiModule) },
  {path: '**', component: PageNotFoundComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes , { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
