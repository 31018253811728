<nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-4">

  <a class="navbar-brand ml-1" routerLink="/">
    <img src="assets/logo-swamp.png" class="img-responsive brand img-brand-swamp" alt="IoT Entity Editor" >
    IoT Entity Editor
  </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/entities">Entities</a>
        </li>

        <!-- <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/templates">Templates</a>
        </li> -->

        <li class="nav-item" routerLinkActive="active" routerLinkActive="active">
          <a class="nav-link" routerLink="/entities/raw-editor">Entity Raw Editor</a>
        </li>

        <li class="nav-item" routerLinkActive="active" routerLinkActive="active">
          <a class="nav-link" routerLink="/subscriptions">Subscriptions</a>
        </li>

        <li class="nav-item dropdown" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Mock Data
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" routerLinkActive="active">
            <!-- <a class="dropdown-item" href="#" [routerLink]="['/mock-data/orion/create']" >Create Orion Mock Data</a>
            <a class="dropdown-item" href="#" [routerLink]="['/mock-data/orion/delete']" >Delete Orion Mock Data</a> -->
            <a class="dropdown-item" href="#" [routerLink]="['/mock-data/entities-config']" >Entity Config</a>
            <a class="dropdown-item" href="#" [routerLink]="['/mock-data/subscriptions-config']" >Subscription Config</a>
          </div>
        </li>

        <li class="nav-item dropdown" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Configurations
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" routerLinkActive="active">
            <a class="dropdown-item" href="#" [routerLink]="['/components-config/orion']" >Orion</a>
            <a class="dropdown-item" href="#" [routerLink]="['/components-config/probe']" > Probes </a>
          </div>
        </li>

        <li class="nav-item dropdown" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Modeler
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" routerLinkActive="active">
            <a class="dropdown-item" href="#" [routerLink]="['/modeler/viewer']" >Json Viewer </a>
            <a class="dropdown-item" href="#" [routerLink]="['/modeler/orion-viewer']" >Orion Viewer </a>
          </div>
        </li>

        <li class="nav-item dropdown" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Weather
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" routerLinkActive="active">
            <a class="dropdown-item" href="#" [routerLink]="['/weather/matopiba']" > Matopiba</a>
          </div>
        </li>

      </ul>
    </div>

    <form class="form-inline">
        <!-- <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> -->
        <select id="pilot" class="form-control mr-sm-4" (change)="onPilotChange(pilotSelected.value)" #pilotSelected>
            <option value="" >Choose your option</option>
            <option *ngFor="let orion of orions" [value]="orion.name">{{ orion.name }}</option>
            <!-- <option value="matopiba">MATOPIBA</option>                 -->
        </select>
      </form>
  </nav>
