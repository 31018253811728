import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PilotModel } from '../models/pilot.model';
import { PilotService } from './pilot.service';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  production : boolean;
  storageKeysConfig;

  constructor() { 
    
    this.production = environment.production;
    this.storageKeysConfig = environment.storageKeysConfig;
  }

}
