import { NgModule } from '@angular/core';

import { CoreModule } from "./core/core.module";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

//ng serve --port 9000 --proxy-config proxy.conf.json
// ng build  --base-href /iotee/




// scp -r dist/entity-editor ubuntu@iotee.swamp-project.org:/home/ubuntu
// sudo cp -r entity-editor/* /var/www/html/iotee/



// scp -r dist/entity-editor fabrizio@fabrizioborelli.com.br:/home/fabrizio
// sudo cp -r entity-editor/* /var/www/html/iotee/



// sudo docker-compose stop
// sudo docker rm fiware-orion
// sudo nohup docker-compose up &

